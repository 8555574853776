@import "../../styles/index.scss";

.slide_container {
  position: relative;
  background-color: $fuLightBlackColor;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: $fuLightBlackColor;
  }
  .slide_parent {
    & {
      padding-bottom: 200px;
      margin-bottom: -200px;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
    }
    .slide {
      height: 500px;
      position: relative;
      z-index: 10;
      .slide_caption {
        .slide_caption_heading {
          color: $fuWhiteColor;
          font-size: 2rem;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
        .slide_caption_descriprion {
          color: $fuWhiteColor;
          text-align: justify;
          font-weight: 200;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 6;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
      }
      .slide_img_size {
        width: 500px;
        height: 500px;
      }
    }
    .slide_bg_blur {
      position: absolute;
      top: -100px;
      right: 0;
      bottom: 0;
      left: 0;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      overflow-y: hidden;
      filter: blur(50px);
      opacity: 0.2;
    }
  }
}

.gradient {
  height: 200px;
  background-image: linear-gradient(to top, $fuDarkGrayColor 25%, rgba($color: $fuDarkGrayColor, $alpha: 0.7) 50%, rgba($color: $fuDarkGrayColor, $alpha: 0.3) 75%, $fuTransparentColor);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}

@media screen and (min-width: 0px) and (max-width: 310px) {
  .slide_container {
    .slide_parent {
      .slide {
        height: auto;
        .slide_caption {
          .slide_caption_heading {
            font-size: 1.3rem;
          }
          .slide_caption_descriprion {
            -webkit-line-clamp: unset;
          }
        }
        .slide_img_size {
          width: 200px;
          height: 200px;
        }
      }
    }
  }
}

@media screen and (min-width: 310px) and (max-width: 400px) {
  .slide_container {
    .slide_parent {
      .slide {
        height: auto;
        .slide_caption {
          .slide_caption_heading {
            font-size: 1.3rem;
          }
          .slide_caption_descriprion {
            -webkit-line-clamp: unset;
          }
        }
        .slide_img_size {
          width: 250px;
          height: 250px;
        }
      }
    }
  }
}

@media screen and (min-width: 400px) and (max-width: 575.9px) {
  .slide_container {
    .slide_parent {
      .slide {
        height: auto;
        .slide_caption {
          .slide_caption_heading {
            font-size: 1.5rem;
          }
          .slide_caption_descriprion {
            -webkit-line-clamp: unset;
          }
        }
        .slide_img_size {
          width: 325px;
          height: 325px;
        }
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .slide_container {
    .slide_parent {
      .slide {
        height: auto;
        .slide_caption {
          .slide_caption_heading {
            font-size: 1.8rem;
          }
        }
        .slide_img_size {
          width: 375px;
          height: 375px;
        }
      }
    }
  }
}

@media screen and (min-width: 767.9px) and (max-width: 991.9px) {
  .slide_container {
    .slide_parent {
      .slide {
        height: auto;
        .slide_caption {
          .slide_caption_heading {
            font-size: 2rem;
          }
        }
        .slide_img_size {
          width: 425px;
          height: 425px;
        }
      }
    }
  }
}

@media screen and (min-width: 991.9px) and (max-width: 1199.9px) {
  .slide_container {
    .slide_parent {
      .slide {
        height: 425px;
        .slide_caption {
          .slide_caption_heading {
            font-size: 2rem;
          }
        }
        .slide_img_size {
          width: 425px;
          height: 425px;
        }
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .slide_container {
    .slide_parent {
      .slide {
        height: 425px;
        .slide_caption {
          .slide_caption_heading {
            font-size: 2rem;
          }
        }
        .slide_img_size {
          width: 425px;
          height: 425px;
        }
      }
    }
  }
}
