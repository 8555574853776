@import "../../styles/index.scss";

.search_btn {
  background-color: $fuTransparentColor;
  border: none;
  color: $fuLightGrayColor;
  transition: all 0.3s;
  &:hover {
    color: $fuYellowColor;
  }
}

.logout_btn {
  & {
    display: inline-flex !important;
    justify-content: center;
    align-items: center;
    background-color: $fuTransparentColor !important;
    border: none !important;
    color: $fuLightGrayColor !important;
    transition: all 0.3s;
  }
  &:hover {
    color: $fuYellowColor !important;
    .user_pic {
      background-color: $fuYellowColor;
      color: $fuDarkGrayColor;
      outline-color: $fuYellowColor;
    }
  }
  &::after {
    content: "";
    border: none !important;
  }
  .user_pic {
    width: 23px;
    height: 23px;
    border-radius: 50%;
    outline: 1px solid $fuLightGrayColor;
    outline-offset: 2px;
    font-size: 12px;
    color: $fuLightGrayColor;
    background-color: $fuTransparentColor;
    font-weight: 400;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 0.3s;
  }
}

.dropdown_menu {
  background-color: $fuLightGrayColor !important;
  .dropdown_item {
    transition: all 0.2s;
    color: $fuDarkGrayColor !important;
  }
}
