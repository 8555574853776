@import "../../styles/index.scss";

.cover_container {
  height: 400px;
  overflow: hidden;
  transform: translate(0, 0, 0);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    inset: 0;
    background-image: radial-gradient(rgba(26, 26, 26, 0.4), rgba(46, 46, 46, 0.2));
    opacity: 0.9;
    // filter: blur(10px);
    z-index: 1;
  }
  .partnership_cover_bg_blur {
    position: absolute;
    inset: 0;
    filter: blur(80px);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    z-index: 0;
    opacity: 0.5;
  }
  .partnership_box {
    & {
      position: relative;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .partnership_pic {
      width: 200px;
      height: 200px;
      border-radius: 4px;
      outline: 3px solid $fuWhiteColor;
      outline-offset: 3px;
      overflow: hidden;
      background-color: $fuDarkGrayColor;
    }
    .partnership_name_container {
      & {
        display: flex;
        align-items: center;
      }
      .partnership_name {
        font-size: 20px;
        font-weight: 700;
        color: $fuWhiteColor;
      }
    }
    .social_box {
      & {
        display: flex;
      }
      .social_icon_container {
        & {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: $fuTransparentColor;
          margin: 0 5px;
          transition: all 0.3s;
          text-decoration: none;
        }
        .social_icon {
          fill: $fuLightGrayColor;
          transition: all 0.3s;
        }
        &:hover {
          background-color: $fuWhiteColor;
          .social_icon {
            fill: $fuYellowColor;
          }
        }
      }
    }
  }
}

.partnership_bio {
  text-align: justify;
  color: $fuLightGrayColor;
}

@media screen and (min-width: 991.9px) and (max-width: 1199.9px) {
  .cover_container {
    .partnership_box {
      .partnership_pic {
        width: 180px;
        height: 180px;
      }
    }
  }
}

@media screen and (min-width: 767.9px) and (max-width: 991.9px) {
  .cover_container {
    height: 350px;
    .partnership_box {
      .partnership_pic {
        width: 160px;
        height: 160px;
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .cover_container {
    height: 300px;
    .partnership_box {
      .partnership_pic {
        width: 140px;
        height: 140px;
      }
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 576px) {
  .cover_container {
    height: 300px;
    .partnership_box {
      .partnership_pic {
        width: 130px;
        height: 130px;
      }

      .partnership_name_container {
        .partnership_name {
          font-size: 16px !important;
          font-weight: 700;
        }
      }
      .social_box {
        & {
          display: flex;
        }
        .social_icon_container {
          & {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 400px) and (max-width: 480px) {
  .cover_container {
    height: 300px;
    .partnership_box {
      .partnership_pic {
        width: 130px;
        height: 130px;
      }

      .partnership_name_container {
        .partnership_name {
          font-size: 16px !important;
          font-weight: 700;
        }
      }
      .social_box {
        & {
          display: flex;
        }
        .social_icon_container {
          & {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 310px) and (max-width: 400px) {
  .cover_container {
    height: 300px;
    .partnership_box {
      .partnership_pic {
        width: 130px;
        height: 130px;
      }

      .partnership_name_container {
        .partnership_name {
          font-size: 16px !important;
          font-weight: 700;
        }
      }
      .social_box {
        & {
          display: flex;
        }
        .social_icon_container {
          & {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 0px) and (max-width: 310px) {
  .cover_container {
    height: 300px;
    .partnership_box {
      .partnership_pic {
        width: 130px;
        height: 130px;
      }

      .partnership_name_container {
        .partnership_name {
          font-size: 16px !important;
          font-weight: 700;
        }
      }
      .social_box {
        & {
          display: flex;
        }
        .social_icon_container {
          & {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }
}
