@import "../../styles/index.scss";

.stream_icons_container {
  display: flex;
  .streaming_links,
  .social_media_links {
    display: flex;
  }
  .stream_icon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    border: 2px solid $fuLightGrayColor;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    transition: all 0.3s;
    .svg_icon {
      fill: $fuLightGrayColor;
      transition: all 0.3s;
    }
    &:hover {
      border-color: $fuYellowColor;
      .svg_icon {
        fill: $fuYellowColor;
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .stream_icons_container {
    & {
      flex-wrap: wrap;
      justify-content: center;
    }
    .social_media_links {
      margin-bottom: 10px;
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 576px) {
  .stream_icons_container {
    & {
      flex-wrap: wrap;
      justify-content: center;
    }
    .stream_icon {
      & {
        width: 30px;
        height: 30px;
      }
    }
    .social_media_links {
      margin-bottom: 10px;
    }
  }
}
@media screen and (min-width: 400px) and (max-width: 480px) {
  .stream_icons_container {
    & {
      flex-wrap: wrap;
      justify-content: center;
    }
    .stream_icon {
      & {
        width: 30px;
        height: 30px;
      }
    }
    .social_media_links {
      margin-bottom: 10px;
    }
  }
}
@media screen and (min-width: 310px) and (max-width: 400px) {
  .stream_icons_container {
    & {
      flex-wrap: wrap;
      justify-content: center;
    }
    .stream_icon {
      & {
        width: 30px;
        height: 30px;
      }
    }
    .social_media_links {
      margin-bottom: 10px;
    }
  }
}
@media screen and (min-width: 0px) and (max-width: 310px) {
  .stream_icons_container {
    & {
      flex-wrap: wrap;
      justify-content: center;
    }
    .stream_icon {
      & {
        width: 20px;
        height: 20px;
      }
      .svg_icon {
        width: 10px !important;
      }
    }
    .social_media_links {
      margin-bottom: 10px;
    }
  }
}
