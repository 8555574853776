@mixin tableItemImgContainer($width, $minWidth, $height, $minHeight) {
  border: 1px solid $fuWhiteColor;
  padding: 3px;
  border-radius: 50%;
  overflow: hidden;
  width: $width;
  min-width: $minWidth;
  height: $height;
  min-height: $minHeight;
}

@mixin squareTableItemImgContainer($width, $minWidth, $height, $minHeight) {
  border: 1px solid $fuWhiteColor;
  padding: 3px;
  border-radius: 4px;
  overflow: hidden;
  width: $width;
  min-width: $minWidth;
  height: $height;
  min-height: $minHeight;
}

@mixin rectangleTableItemImgContainer($width, $minWidth, $minHeight) {
  border: 1px solid $fuWhiteColor;
  padding: 3px;
  border-radius: 4px;
  overflow: hidden;
  width: $width;
  min-width: $minWidth;
  min-height: $minHeight;
}
