@import "../../styles/index.scss";

.fm_bill {
  display: flex;
  align-items: center;
  color: $fuYellowColor;
  margin-right: 10px;
  padding: 0 30px;
  border-radius: 50px;
  border: 2px solid $fuYellowColor;
  line-height: 1 !important;
  transition: all 0.2s;
}
.db_word {
  display: block;
  line-height: 1 !important;
  border: 2px solid $fuTransparentColor;
  // padding-bottom: 8px;
  transition: all 0.2s;
}

.dash {
  display: inline-flex;
  width: 25px;
  height: 10px;
  border-radius: 50px;
  margin: 0 6px;
  border: 2px solid $fuYellowColor;
  transform: translateY(50%);
  background-color: $fuYellowColor;
  transition: all 0.2s;
}

.see_all_link {
  text-decoration: underline dotted;
  text-underline-offset: 2px;
  color: $fuLightGrayColor;
  transition: all 0.3s;
  &:hover {
    color: $fuYellowColor;
  }
}

@media screen and (min-width: 0) and (max-width: 767.9px) {
  .fm_bill {
    padding: 0 20px;
  }
  // .db_word {
  //   padding-bottom: 4px;
  // }
  .dash {
    width: 16px;
    height: 6px;
    transform: translateY(45%);
  }
}

@media screen and (min-width: 767.9px) and (max-width: 1199.9px) {
  .fm_bill {
    padding: 0 25px;
  }
  // .db_word {
  //   padding-bottom: 8px;
  // }
  .dash {
    width: 20px;
    height: 8px;
  }
}

@media screen and (min-width: 310px) and (max-width: 400px) {
  .fm_bill {
    padding: 0 18px;
    margin-right: 8px;
  }
  // .db_word {
  //   padding-bottom: 2px;
  // }
  .dash {
    width: 10px;
    height: 4px;
  }
}

@media screen and (min-width: 0) and (max-width: 310px) {
  .fm_bill {
    padding: 0 16px;
    margin-right: 6px;
  }
  // .db_word {
  //   padding-bottom: 0px;
  // }
  .dash {
    width: 8px;
    height: 4px;
    transform: translateY(35%);
  }
}
