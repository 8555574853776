@import "../../styles/index.scss";

.loading_layout {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $fuDarkGrayColor;
  z-index: 99999;
  overflow: hidden !important;
}

.loading_screen {
  position: relative;
  height: 100vh;
  background-color: $fuDarkGrayColor;
}

.logo_container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.logo_container img {
  width: 80px;
  height: 80px;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 85px;
  height: 85px;
  margin: auto;
}
.loader .circle {
  position: absolute;
  width: 85px;
  height: 85px;
  opacity: 0;
  transform: rotate(225deg);
  animation-iteration-count: infinite;
  animation-name: orbit;
  animation-duration: 5.5s;
}
.loader .circle:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  border-radius: 5px;
  background: $fuYellowColor;
  box-shadow: 0 0 9px rgba($color: $fuYellowColor, $alpha: 0.7);
}
.loader .circle:nth-child(1) {
  animation-delay: 240ms;
}
.loader .circle:nth-child(2) {
  animation-delay: 480ms;
}
.loader .circle:nth-child(3) {
  animation-delay: 720ms;
}
.loader .circle:nth-child(4) {
  animation-delay: 960ms;
}

@keyframes orbit {
  0% {
    transform: rotate(225deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  7% {
    transform: rotate(345deg);
    animation-timing-function: linear;
  }
  30% {
    transform: rotate(455deg);
    animation-timing-function: ease-in-out;
  }
  39% {
    transform: rotate(690deg);
    animation-timing-function: linear;
  }
  70% {
    transform: rotate(815deg);
    opacity: 1;
    animation-timing-function: ease-out;
  }
  75% {
    transform: rotate(945deg);
    animation-timing-function: ease-out;
  }
  76% {
    transform: rotate(945deg);
    opacity: 0;
  }
  100% {
    transform: rotate(945deg);
    opacity: 0;
  }
}
