$fuYellowColor: #fdb915;
$fuDarkGrayColor: #191919;
$fuMidGrayColor: #2f2f2f;
$fuLightGrayColor: #a5a5a5;
$fuBlackColor: #000000;
$fuLightBlackColor: #141414;
$fuOffWhiteColor: #cacaca;
$fuWhiteColor: #ffffff;
$fuTransparentColor: transparent;
$fuAlertColor: #b02a37;
$fuGreenColor: #46ab5e;
