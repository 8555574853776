@import "../../styles/index.scss";

.mobile_bar_container {
  background-color: $fuDarkGrayColor;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999;
  box-shadow: 0 0 10px 2px $fuLightBlackColor;
  transform: translate3d(0, 0, 0) !important;
  .mobile_bar {
    .bar_logo_container {
      .icon_container {
        position: relative;
        z-index: 1;
        .icon_width {
          margin-right: 10px;
          display: flex;
          justify-content: center;
          .icon {
            width: 26px;
          }
        }
      }

      .logo_container {
        position: relative;
        z-index: 0;
        .logo_width {
          width: 120px;
          .logo {
            width: 120px;
          }
        }
      }
    }

    .bar_btn {
      color: $fuLightGrayColor;
      transition: all 0.3s;
      &:hover {
        color: $fuYellowColor;
      }
    }
  }
}

.side_nav {
  z-index: 99999999 !important;
  .icon_container {
    position: relative;
    z-index: 1;
    transition: background-color 0.1s;
    &::before {
      content: "";
      position: absolute;
      left: 0;
      height: 100%;
      width: 75%;
      background-color: $fuBlackColor;
      z-index: -1;
    }
    .icon_width {
      width: 70px;
      display: flex;
      justify-content: center;
      .icon {
        width: 32.5px;
      }
    }
  }

  .logo_container {
    position: relative;
    z-index: 0;
    .logo_width {
      width: 150px;
      transform: translateX(-80%);
      opacity: 0;
      transition: opacity 0.3s, transform 0.3s;
      .logo {
        width: 140px;
      }
    }
  }

  .nav_link_container {
    text-decoration: none;
    border: none;
    outline: none;
    width: 100%;
    background-color: $fuTransparentColor;
    padding: 0;
    cursor: pointer;
    .link_icon_container {
      width: 70px;
      text-align: center;
      font-size: 16px;
      position: relative;
      z-index: 1;
      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: 100%;
        width: 75%;
        background-color: $fuBlackColor;
        z-index: -1;
      }
      .link_icon {
        color: $fuLightGrayColor !important;
        transition: color 0.3s;
      }
      .svg_icon {
        fill: $fuLightGrayColor !important;
        transition: fill 0.3s;
      }
    }

    .link_text_container {
      opacity: 0;
      position: relative;
      z-index: 0;
      transform: translateX(-100%);
      transition: opacity 0.2s, transform 0.3s !important;
      .link_text {
        white-space: nowrap;
        font-size: 16px !important;
        font-weight: 300;
        color: $fuLightGrayColor !important;
        transition: color 0.3s;
      }
    }

    &:hover {
      .link_icon {
        color: $fuYellowColor !important;
      }
      .svg_icon {
        fill: $fuYellowColor !important;
      }
      .link_text {
        color: $fuWhiteColor !important;
      }
    }
  }
}

.active {
  .nav_link_container {
    text-decoration: none;
    border: none;
    outline: none;
    width: 100%;
    background-color: $fuTransparentColor;
    padding: 0;
    .link_icon_container {
      .link_icon {
        color: $fuYellowColor !important;
      }
    }
    .link_text_container {
      .link_text {
        color: $fuWhiteColor !important;
      }
    }
  }
}

.fu_btn_gray {
  background-color: $fuTransparentColor !important;
  border: 1px solid $fuLightGrayColor !important;
  color: $fuLightGrayColor !important;
  border-radius: 50px !important;
  transition: all 0.4s ease-out;
  overflow: hidden;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  .share_icon_svg {
    // fill: none;
    stroke: $fuLightGrayColor;
    fill: $fuLightGrayColor;
    transition: fill 0.3s ease-out, stroke 0.3s ease-out;
  }
  .btn_bg {
    position: absolute;
    overflow: hidden !important;
    width: 100%;
    height: 100%;
    z-index: 0;
    transform: rotate(10deg) translateX(100%);
    background-color: $fuYellowColor;
    border-radius: 50px;
    transition: all 0.5s ease-out;
  }
  .search_icon {
    position: relative;
  }
  &:hover {
    border-color: $fuYellowColor !important;
    color: $fuBlackColor !important;
    transition: all 0.3s ease-out;

    .btn_bg {
      width: 250%;
      height: 250%;
      transform: rotate(10deg) translateX(0) !important;
      transition: all 0.3s ease-out;
    }
    .share_icon_svg {
      fill: $fuBlackColor;
      stroke: $fuBlackColor;
      transition: all 0.15s ease-in;
    }
  }
}

.fu_btn {
  padding: 8px 16px;
  line-height: 1.2 !important;
  text-decoration: none;
  display: inline-flex;
}
