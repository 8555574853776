@import "../../styles/index.scss";

.video_container {
  position: relative;
  background-color: $fuLightBlackColor;
  padding-bottom: 500px;
  margin-bottom: -500px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  .video_info_section {
    & {
      position: relative;
      z-index: 10;
    }
    .video_info {
      .video_title {
        color: $fuWhiteColor;
        font-size: 2rem;
        font-weight: 700;
        // line-height: 1;
      }
      .video_artist {
        display: block;
        font-size: 16px;
        font-weight: 300;
        color: $fuLightGrayColor;
      }
    }
  }

  .bg_blur {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow-y: hidden;
    filter: blur(50px);
    opacity: 0.2;
  }
  .gradient {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 600px;
    background-image: linear-gradient(to top, $fuDarkGrayColor 25%, rgba($color: $fuDarkGrayColor, $alpha: 0.7) 50%, rgba($color: $fuDarkGrayColor, $alpha: 0.3) 75%, $fuTransparentColor);
  }
}
