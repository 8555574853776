@import "./colors";
@import "./mixins";

@media screen and (min-width: 0) and (max-width: 1249.9px) {
  .layout-container {
    display: block;
  }
  .fu-btn {
    padding: 5px 14px;
    font-size: 14px;
  }
  .item-search-box-container {
    input {
      padding: 4px 14px !important;
    }
  }

  .outlet {
    transform: unset;
  }

  .offcanvas[aria-modal="true"] {
    width: 22rem;
  }

  .mobile-nav {
    .mobile-side-nav {
      overflow-y: auto;
      overflow-x: hidden;
      &::-webkit-scrollbar-button:single-button:vertical:decrement {
        display: none;
      }
      &::-webkit-scrollbar-button:single-button:vertical:increment {
        display: none;
      }

      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-moz-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background-color: $fuBlackColor;
      }

      &::-moz-scrollbar-track {
        background-color: $fuBlackColor;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 8px;

        border: 1px solid transparent;
        background-clip: content-box;
        background-color: #fdb915;
        transition: all 0.2s;
        &:hover {
          background-color: #d19914;
        }
      }

      &::-moz-scrollbar-thumb {
        border-radius: 8px;
        border: 1px solid transparent;
        background-clip: content-box;
        background-color: #fdb915;
        transition: all 0.2s;
        &:hover {
          background-color: #d19914;
        }
      }
    }
    .mobile-nav-home-btn {
      position: relative;
      .close-mobile-nav-btn {
        opacity: 0;
        width: 35px;
        height: 35px;
        position: absolute;
        top: calc(50% + 10px);
        right: 0;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        background-color: $fuDarkGrayColor;
        border: 2px solid $fuLightGrayColor;
        color: $fuLightGrayColor;
        font-size: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.2s;
        &:hover {
          color: $fuYellowColor;
          border-color: $fuYellowColor;
        }
      }
    }

    .show {
      .logo-container {
        .logo-width {
          transform: translateX(-10px);
          opacity: 1;
          transition: opacity 0.4s, transform 0.3s;
          transition-delay: 0.2s;
        }
      }
      .nav-link-container {
        .link-text-container {
          transform: translateX(-10px);
          opacity: 1;
          transition: opacity 0.4s, transform 0.3s !important;
        }
        @for $i from 1 through 20 {
          .link-text-container-#{$i} {
            transition-delay: $i * 0.04s !important;
          }
        }
      }
      .mobile-nav-home-btn {
        .close-mobile-nav-btn {
          opacity: 1;
        }
      }
    }
  }

  .virtual-mobile-bar-height {
    background-color: $fuDarkGrayColor;
    height: 64px;
    width: 100%;
  }

  .mobile-nav:has(> .show) + .mobile-bar-container {
    .bar-btn {
      color: $fuYellowColor !important;
      transition: all 0.3s !important;
    }
  }

  .offcanvas-backdrop {
    z-index: 9999999 !important;
  }

  .custom-height {
    min-height: calc(100vh - 45px - 64px);
  }
}

@media screen and (min-width: 0) and (max-width: 991.9px) {
  .iu-row-heading {
    width: 100%;
  }
  .row-search-container {
    width: 100%;
  }
  .item-search-box-container {
    .item-search-box {
      width: 100%;
    }
  }
}

@media screen and (min-width: 0) and (max-width: 767.9px) {
  .fu-text-2-rem-sm {
    font-size: 2rem !important;
  }
  .release-container {
    width: 75%;
    margin: auto;
  }
  .w-sm-fit-content {
    width: fit-content !important;
    align-self: center;
  }
}

@media screen and (min-width: 0) and (max-width: 576px) {
  .form-explain {
    display: none !important;
  }
  .info-tags-container {
    .info-tag {
      font-size: 14px;
    }
  }

  .mobile-nav {
    .icon-container {
      .icon-width {
        width: 60px;
        .icon {
          width: 26px !important;
        }
      }
    }
    .logo-container {
      .logo-width {
        width: 120px;
        .logo {
          width: 120px;
        }
      }
    }
  }

  .swiper {
    .swiper-button-prev,
    .swiper-button-next {
      width: 30px;
      height: 30px;
      &::after {
        font-size: 12px !important;
      }
    }
  }

  .update-img-show {
    @include tableItemImgContainer(50px, 50px, 50px, 50px);
  }

  .page-heading-title-sm-2-rem {
    font-size: 2rem !important;
  }
}

@media screen and (min-width: 0) and (max-width: 480px) {
  .Toastify__toast-container {
    top: 16px !important;
    left: unset !important;
    .Toastify__toast {
      border-radius: 4px;
      min-height: 44px;
      margin-bottom: 12px;
      .Toastify__toast-body {
        font-size: 14px;
        padding: 0 6px;
        margin: 0;
      }
    }
  }
}

@media screen and (min-width: 0) and (max-width: 310px) {
  .swal2-popup {
    .swal2-icon {
      & {
        margin-top: 2rem;
        width: 2.5rem;
        height: 2.5rem;
      }
      .swal2-icon-content {
        font-size: 2rem;
      }
    }
    .swal2-title {
      font-size: 1.5rem;
      padding-top: 5px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 14px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .Toastify__toast-container {
    .Toastify__toast {
      .Toastify__toast-body {
        font-size: 12px;
      }
    }
  }

  .toast-msg-container-parent {
    .toast-msg-container {
      width: max-content !important;
    }
    .Toastify__toast {
      width: max-content !important;
      max-width: 200px !important;
      margin-left: auto !important;
      .Toastify__toast-body {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 200px;
    }
  }
  .create-submit-row {
    display: none;
  }
  .fu-text-18-px-xxxs {
    font-size: 18px !important;
  }

  .w-sm-max {
    width: max-content;
  }

  .mobile-nav {
    .mobile-nav-home-btn {
      .close-mobile-nav-btn {
        width: 25px;
        height: 25px;
        font-size: 16px;
      }
    }
  }

  .offcanvas[aria-modal="true"] {
    width: 16rem;
  }

  .fu-breadcrumb {
    display: none;
  }

  .fu-text-16-px-xxs {
    font-size: 16px !important;
  }

  .fu-text-12-px-xxs {
    font-size: 12px !important;
  }

  .iu-row-heading {
    .row-title {
      font-size: 16px;
    }
  }

  .info-tags-container {
    .info-tag {
      font-size: 9px;
    }
  }

  .release-container {
    width: 100%;
    .release-img-container {
      .listen-now-layer {
        .listen-text {
          font-size: 14px;
        }
      }
    }
  }

  .video-container {
    width: 100%;
    height: auto;
    margin: auto;
    .yt-iframe-box {
      .i-frame-container {
        aspect-ratio: 16 / 9;
        .i-frame-play-icon {
          width: 35px;
          height: 35px;
          font-size: 20px;
        }
      }
    }
  }

  .release-caption,
  .fu-video-caption,
  .fu-blog-caption {
    .release-name,
    .fu-release-video-name,
    .fu-blog-title {
      -webkit-line-clamp: 1;
      font-size: 14px;
    }
    .release-artist,
    .fu-channel-video-name {
      font-size: 12px;
    }
  }

  .artist-box,
  .label-box {
    width: fit-content;
    margin: auto;
  }

  input::placeholder {
    font-size: 9px !important;
  }

  textarea::placeholder {
    font-size: 9px !important;
  }

  .css-1jqq78o-placeholder {
    font-size: 9px !important;
  }

  .fu-btn {
    width: max-content !important;
  }

  .item-releases-search-row-height {
    min-height: 353px;
    transition: all 0.3s;
  }

  .item-releases-search-msg-height {
    min-height: 353px;
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .item-videos-search-row-height {
    min-height: 238px;
    transition: all 0.3s;
  }

  .item-videos-search-msg-height {
    min-height: 238px;
  }

  .item-videos-search-msg-height {
    min-height: 238px;
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  // ----------------
  .item-genres-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-artists-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-labels-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-news-search-row-height {
    min-height: 167.3px;
    transition: all 0.3s;
  }

  .item-news-search-msg-height {
    min-height: 167.3px;
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .item-role-users-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-role-permissions-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-msg {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .footer-text {
    font-size: 8px;
  }

  .page-heading-title {
    font-size: 1.5rem;
  }

  .row-info {
    font-size: 12px !important;
    font-weight: 400;
  }

  // $width, $minWidth, $height, $minHeight
  .table-item-img-container {
    @include tableItemImgContainer(50px, 50px, 50px, 50px);
  }

  // $width, $minWidth, $height, $minHeight
  .square-table-item-img-container {
    @include squareTableItemImgContainer(50px, 50px, 50px, 50px);
  }

  // $width, $minWidth, $minHeight
  .rectangle-table-item-img-container {
    @include rectangleTableItemImgContainer(50px, 50px, 30px);
  }

  .tf-limitation-msg,
  .limitation-msg {
    i {
      font-size: 40px !important;
    }
    h1 {
      font-size: 18px !important;
      text-align: center;
    }
    h2 {
      font-size: 16px;
      text-align: center;
    }
  }
  .release-container {
    .release-img-container {
      width: auto;
      height: auto;
    }
  }
  .blog-box {
    .blog-img-container {
      width: auto;
      height: auto;
    }
  }
}

@media screen and (min-width: 400px) and (max-width: 480px) {
  .swal2-popup {
    & {
      width: 330px;
    }
    .swal2-icon {
      & {
        margin-top: 2rem;
        width: 2.9rem;
        height: 2.9rem;
      }
      .swal2-icon-content {
        font-size: 2.2rem;
      }
    }
    .swal2-title {
      font-size: 1.5rem;
      padding-top: 5px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 14px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .toast-msg-container-parent {
    .toast-msg-container {
      width: max-content !important;
    }
    .Toastify__toast {
      width: max-content !important;
      max-width: 300px !important;
      margin-left: auto !important;
      .Toastify__toast-body {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
      }
    }
  }

  .fu-text-xs-1-2-rem {
    font-size: 1.5rem !important;
  }
  .w-sm-max {
    width: max-content;
  }
  .mobile-nav {
    .mobile-nav-home-btn {
      .close-mobile-nav-btn {
        width: 35px;
        height: 35px;
        font-size: 20px;
      }
    }
  }

  .label-tags,
  .artist-tags,
  .genre-tags,
  .release-tags,
  .video-tags,
  .new-blog-tags,
  .tf-artists-tags,
  .tf-releases-tags,
  .partnerships-tags,
  .team-members-tags,
  .contact-message-tags,
  .demos-tags,
  .page-header-tags,
  .home-slides-tags,
  .futurising-music-tags,
  .privacy-polict-tags,
  .user-tags,
  .role-tags {
    .info-tags-container {
      .info-tag {
        font-size: 11px;
      }
    }
  }

  .new-blog-container {
    width: 358px;
    margin: auto;
  }

  .offcanvas[aria-modal="true"] {
    width: 19rem;
  }

  .fu-text-12-px-sm {
    font-size: 12px !important;
  }

  .fu-text-1-8-rem-xs {
    font-size: 1.8rem !important;
  }

  .fu-breadcrumb {
    display: none;
  }
  .video-container {
    width: fit-content;
    margin: auto;
    .yt-iframe-box {
      .i-frame-container {
        height: 175px;
        aspect-ratio: 16 / 9;
      }
    }
  }
  .item-releases-search-row-height {
    min-height: 394px;
    transition: all 0.3s;
  }

  .item-releases-search-msg-height {
    min-height: 394px;
  }
  .item-videos-search-row-height {
    min-height: 273px;
    transition: all 0.3s;
  }

  .item-videos-search-msg-height {
    min-height: 273px;
  }

  .item-news-search-row-height {
    min-height: 203.7px;
    transition: all 0.3s;
  }

  .item-news-search-msg-height {
    min-height: 203.7px;
  }

  .footer-text {
    font-size: 10px;
  }
  .page-heading-title {
    font-size: 2.5rem;
  }
  .row-info {
    font-weight: 400;
  }

  // $width, $minWidth, $height, $minHeight
  .table-item-img-container {
    @include tableItemImgContainer(60px, 60px, 60px, 60px);
  }

  // $width, $minWidth, $height, $minHeight
  .square-table-item-img-container {
    @include squareTableItemImgContainer(60px, 60px, 60px, 60px);
  }

  // $width, $minWidth, $minHeight
  .rectangle-table-item-img-container {
    @include rectangleTableItemImgContainer(60px, 60px, 35px);
  }
  .update-img-show {
    @include tableItemImgContainer(50px, 50px, 50px, 50px);
  }
  .tf-limitation-msg,
  .limitation-msg {
    i {
      font-size: 40px !important;
    }
    h1 {
      font-size: 18px !important;
      text-align: center;
    }
    h2 {
      font-size: 16px;
      text-align: center;
    }
  }
  .release-container {
    .release-img-container {
      width: 260px;
      height: 260px;
    }
  }
  .blog-box {
    .blog-img-container {
      width: 342px;
      height: 126.58px;
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 576px) {
  .swal2-popup {
    & {
      width: 360px;
    }
    .swal2-icon {
      & {
        margin-top: 2.3rem;
        width: 3.5rem;
        height: 3.5rem;
      }
      .swal2-icon-content {
        font-size: 2.8rem;
      }
    }
    .swal2-title {
      font-size: 1.5rem;
      padding-top: 5px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 16px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .toast-msg-container-parent {
    .toast-msg-container {
      width: max-content !important;
    }
    .Toastify__toast {
      width: max-content !important;
      max-width: 320px !important;
      margin-left: auto !important;
      .Toastify__toast-body {
        font-size: 14px;
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
      }
    }
  }

  .w-sm-max {
    width: max-content;
  }
  .offcanvas[aria-modal="true"] {
    width: 20rem;
  }

  .fu-text-sm-2-rem {
    font-size: 2rem !important;
  }

  .fu-text-sm-1-5-rem {
    font-size: 1.5rem !important;
  }

  .fu-breadcrumb {
    display: none;
  }
  .video-container {
    width: fit-content;
    margin: auto;
    .yt-iframe-box {
      .i-frame-container {
        height: 220px;
        aspect-ratio: 16 / 9;
      }
    }
  }

  .new-blog-container {
    width: 438px;
    margin: auto;
  }
  .item-releases-search-row-height {
    min-height: 394px;
    transition: all 0.3s;
  }

  .item-releases-search-msg-height {
    min-height: 394px;
  }
  .item-videos-search-row-height {
    min-height: 318px;
    transition: all 0.3s;
  }

  .item-videos-search-msg-height {
    min-height: 318px;
  }

  .item-news-search-row-height {
    min-height: 233.3px;
    transition: all 0.3s;
  }

  .item-news-search-msg-height {
    min-height: 233.3px;
  }

  .footer-text {
    font-size: 12px;
  }

  .page-heading-title {
    font-size: 3rem;
  }
  .tf-limitation-msg,
  .limitation-msg {
    i {
      font-size: 40px !important;
    }
    h1 {
      font-size: 18px !important;
      text-align: center;
    }
    h2 {
      font-size: 16px;
      text-align: center;
    }
  }
  .square-table-item-img-container {
    @include squareTableItemImgContainer(60px, 60px, 60px, 60px);
  }
  .release-container {
    .release-img-container {
      width: 260px;
      height: 260px;
    }
  }
  .blog-box {
    .blog-img-container {
      width: 422px;
      height: 156.2px;
    }
  }
}

@media screen and (min-width: 310px) and (max-width: 400px) {
  .swal2-popup {
    & {
      width: 270px;
    }
    .swal2-icon {
      & {
        margin-top: 2rem;
        width: 2.5rem;
        height: 2.5rem;
      }
      .swal2-icon-content {
        font-size: 2rem;
      }
    }
    .swal2-title {
      font-size: 1.5rem;
      padding-top: 5px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 14px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .toast-msg-container-parent {
    .toast-msg-container {
      width: max-content !important;
    }
    .Toastify__toast {
      width: max-content !important;
      max-width: 250px !important;
      margin-left: auto !important;
      .Toastify__toast-body {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 250px;
    }
  }

  .create-submit-row {
    display: none;
  }

  .w-sm-max {
    width: max-content;
  }
  .mobile-nav {
    .mobile-nav-home-btn {
      .close-mobile-nav-btn {
        width: 30px;
        height: 30px;
        font-size: 18px;
      }
    }
  }

  .offcanvas[aria-modal="true"] {
    width: 17rem;
  }

  .fu-text-12-px-xs {
    font-size: 12px !important;
  }
  .fu-text-18-px-xxs {
    font-size: 18px !important;
  }
  .fu-breadcrumb {
    display: none;
  }

  .iu-row-heading {
    .row-title {
      font-size: 16px;
    }
  }
  .fu-text-22-px-xs {
    font-size: 22px !important;
  }
  .info-tags-container {
    .info-tag {
      font-size: 11px;
    }
  }

  .release-container {
    width: fit-content;
    .release-img-container {
      width: 220px;

      .listen-now-layer {
        .listen-text {
          font-size: 14px;
        }
      }
    }
    .release-caption {
      width: 220px;
    }
  }

  .video-container {
    width: 268px;
    margin: auto;
    .yt-iframe-box {
      .i-frame-container {
        .i-frame-play-icon {
          width: 35px;
          height: 35px;
          font-size: 20px;
        }
      }
    }
  }

  .release-caption,
  .fu-video-caption,
  .fu-blog-caption {
    .release-name,
    .fu-release-video-name,
    .fu-blog-title {
      -webkit-line-clamp: 1;
      font-size: 14px;
    }
    .release-artist,
    .fu-channel-video-name {
      font-size: 12px;
    }
  }

  .new-blog-container {
    width: 268px;
    margin: auto;
  }

  .artist-box,
  .label-box {
    width: fit-content;
    margin: auto;
  }

  input::placeholder {
    font-size: 12px;
  }

  textarea::placeholder {
    font-size: 12px;
  }

  .css-1jqq78o-placeholder {
    font-size: 12px;
  }
  .fu-btn {
    width: max-content;
  }
  .item-releases-search-row-height {
    min-height: 354px;
  }

  .item-releases-search-msg-height {
    min-height: 354px;
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-videos-search-row-height {
    min-height: 239.7px;
  }

  .item-videos-search-msg-height {
    min-height: 239.7px;
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  // ----------------
  .item-genres-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-artists-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-labels-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .item-news-search-row-height {
    min-height: 167.3px;
    transition: all 0.3s;
  }

  .item-news-search-msg-height {
    min-height: 167.3px;
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .item-role-users-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-role-permissions-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-msg {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .footer-text {
    font-size: 8px;
  }
  .page-heading-title {
    font-size: 2rem;
  }

  .row-info {
    font-size: 12px !important;
    font-weight: 400;
  }

  // $width, $minWidth, $height, $minHeight
  .table-item-img-container {
    @include tableItemImgContainer(50px, 50px, 50px, 50px);
  }

  // $width, $minWidth, $height, $minHeight
  .square-table-item-img-container {
    @include squareTableItemImgContainer(50px, 50px, 50px, 50px);
  }

  // $width, $minWidth, $minHeight
  .rectangle-table-item-img-container {
    @include rectangleTableItemImgContainer(50px, 50px, 30px);
  }
  .tf-limitation-msg,
  .limitation-msg {
    i {
      font-size: 40px !important;
    }
    h1 {
      font-size: 18px !important;
      text-align: center;
    }
    h2 {
      font-size: 16px;
      text-align: center;
    }
  }
  .release-container {
    .release-img-container {
      width: 220px;
      height: 220px;
    }
  }
  .blog-box {
    .blog-img-container {
      width: 252px;
      height: 93.27px;
    }
  }
}

@media screen and (min-width: 400px) and (max-width: 575.9px) {
  .page-heading {
    .heading-img-container {
      height: 300px;
    }
  }

  .release-container {
    width: fit-content;
    .release-img-container {
      width: 260px;
      .listen-now-layer {
        .listen-text {
          font-size: 14px;
        }
      }
    }
    .release-caption {
      width: 260px;
    }
  }

  .video-container {
    .yt-iframe-box {
      .i-frame-container {
        .i-frame-play-icon {
          width: 35px;
          height: 35px;
          font-size: 20px;
        }
      }
    }
  }

  .release-caption,
  .fu-video-caption,
  .fu-blog-caption {
    .release-name,
    .fu-release-video-name,
    .fu-blog-title {
      -webkit-line-clamp: 1;
    }
  }

  .artist-box,
  .label-box {
    width: fit-content;
    margin: auto;
  }

  input::placeholder {
    font-size: 13px;
  }

  textarea::placeholder {
    font-size: 13px;
  }

  .css-1jqq78o-placeholder {
    font-size: 13px;
  }

  // ----------------
  .item-genres-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-artists-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-labels-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-releases-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-videos-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .item-news-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .item-role-users-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-role-permissions-search-msg-height {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }
  .item-msg {
    h2 {
      font-size: 35px !important;
    }
    h3 {
      font-size: 14px !important;
    }
  }

  .create-submit-row {
    .row-title {
      font-size: 16px !important;
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .swal2-popup {
    & {
      width: 380px;
    }
    .swal2-icon {
      & {
        margin-top: 2.5rem;
        width: 3.7rem;
        height: 3.7rem;
      }
      .swal2-icon-content {
        font-size: 3rem;
      }
    }
    .swal2-title {
      font-size: 1.6rem;
      padding-top: 5px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 16px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .toast-msg-container-parent {
    .toast-msg-container {
      width: max-content !important;
    }
    .Toastify__toast {
      width: max-content !important;
      max-width: 350px !important;
      margin-left: auto !important;
      .Toastify__toast-body {
        div {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          -webkit-word-break: break-word;
          word-break: break-word;
        }
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 350px;
    }
  }
  .fu-text-md-2-rem {
    font-size: 2rem !important;
  }
  .fu-text-md-1-2-rem {
    font-size: 1.2rem !important;
  }
  .fu-breadcrumb {
    .fu-breadcrumb-item {
      .fu-link-text {
        font-size: 10px !important;
        font-weight: 300;
      }
    }
  }
  .w-sm-max {
    width: max-content;
  }
  .info-tags-container {
    .info-tag {
      font-size: 14px;
    }
  }
  .item-releases-search-row-height {
    min-height: 473px;
    transition: all 0.3s;
  }

  .item-releases-search-msg-height {
    min-height: 473px;
    h2 {
      font-size: 40px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }

  .item-videos-search-row-height {
    min-height: 393.25px;
    transition: all 0.3s;
  }

  .item-videos-search-msg-height {
    min-height: 393.25px;
    h2 {
      font-size: 40px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }

  // ----------------
  .item-genres-search-msg-height {
    h2 {
      font-size: 40px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }
  .item-artists-search-msg-height {
    h2 {
      font-size: 40px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }
  .item-labels-search-msg-height {
    h2 {
      font-size: 40px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }

  .item-news-search-row-height {
    min-height: 306.4px;
    transition: all 0.3s;
  }

  .item-news-search-msg-height {
    min-height: 306.4px;
    h2 {
      font-size: 40px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }

  .item-role-users-search-msg-height {
    h2 {
      font-size: 40px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }
  .item-role-permissions-search-msg-height {
    h2 {
      font-size: 40px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }
  .item-msg {
    h2 {
      font-size: 40px !important;
    }
    h3 {
      font-size: 16px !important;
    }
  }

  .page-heading-title {
    font-size: 4rem;
  }

  .fu-cr-new-head {
    font-size: 3rem;
  }

  .fu-up-new-head {
    font-size: 3rem;
  }

  .update-img-show {
    @include tableItemImgContainer(50px, 50px, 50px, 50px);
  }
  .tf-limitation-msg,
  .limitation-msg {
    i {
      font-size: 40px !important;
    }
    h1 {
      font-size: 18px !important;
      text-align: center;
    }
    h2 {
      font-size: 16px;
      text-align: center;
    }
  }
  .square-table-item-img-container {
    @include squareTableItemImgContainer(60px, 60px, 60px, 60px);
  }
  .release-container {
    .release-img-container {
      width: 339px;
      height: 339px;
    }
  }
  .blog-box {
    .blog-img-container {
      width: 484px;
      height: 179.14px;
    }
  }
}

@media screen and (min-width: 767.9px) and (max-width: 991.9px) {
  .swal2-popup {
    & {
      width: 410px;
    }
    .swal2-icon {
      & {
        margin-top: 2.5rem;
        width: 4rem;
        height: 4rem;
      }
      .swal2-icon-content {
        font-size: 3.1rem;
      }
    }
    .swal2-title {
      font-size: 1.7rem;
      padding-top: 12px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 16px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 400px;
    }
  }
  .w-sm-max {
    width: max-content;
  }
  .fu-btn {
    padding: 5px 14px;
    font-size: 14px;
  }
  .fu-text-3-rem-md {
    font-size: 3rem !important;
  }
  .fu-text-3-rem-lg {
    font-size: 3rem !important;
  }

  .fu-text-2-rem-lg {
    font-size: 2rem !important;
  }

  .item-releases-search-row-height {
    min-height: 429px;
    transition: all 0.3s;
  }

  .item-releases-search-msg-height {
    min-height: 429px;
  }

  .item-videos-search-row-height {
    min-height: 494.5px;
    transition: all 0.3s;
  }

  .item-videos-search-msg-height {
    min-height: 494.5px;
  }

  .item-news-search-row-height {
    min-height: 373.1px;
    transition: all 0.3s;
  }

  .item-news-search-msg-height {
    min-height: 373.1px;
  }

  .update-img-show {
    @include tableItemImgContainer(60px, 60px, 60px, 60px);
  }
  .page-heading-title {
    font-size: 4rem;
  }
  .tf-limitation-msg,
  .limitation-msg {
    i {
      font-size: 50px !important;
    }
    h1 {
      font-size: 20px !important;
      text-align: center;
    }
    h2 {
      font-size: 18px;
      text-align: center;
    }
  }
  .release-container {
    .release-img-container {
      width: 295px;
      height: 295px;
    }
  }
  .blog-box {
    .blog-img-container {
      width: 648px;
      height: 239.84px;
    }
  }
}

@media screen and (min-width: 991.9px) and (max-width: 1199.9px) {
  .swal2-popup {
    & {
      width: 450px;
    }
    .swal2-icon {
      & {
        margin-top: 2.5rem;
        width: 4.2rem;
        height: 4.2rem;
      }
      .swal2-icon-content {
        font-size: 3.1rem;
      }
    }
    .swal2-title {
      font-size: 1.7rem;
      padding-top: 12px;
    }
    .swal2-html-container {
      margin-top: 10px;
      font-size: 16px;
    }
    .swal2-actions {
      & {
        margin-top: 16px;
      }
      .popup-btn {
        padding: 5px 14px;
      }
    }
  }

  .page-heading {
    .heading-img-container {
      height: 430px;
    }
  }
  .w-sm-max {
    width: max-content;
  }

  .fu-text-3-rem-lg {
    font-size: 3rem !important;
  }

  .item-releases-search-row-height {
    min-height: 391.3px;
    transition: all 0.3s;
  }

  .item-releases-search-msg-height {
    min-height: 391.3px;
  }

  .item-videos-search-row-height {
    min-height: 360.6px;
    transition: all 0.3s;
  }

  .item-videos-search-msg-height {
    min-height: 360.6px;
  }

  .item-news-search-row-height {
    min-height: 375.3px;
    transition: all 0.3s;
  }

  .item-news-search-msg-height {
    min-height: 375.3px;
  }

  .update-img-show {
    @include tableItemImgContainer(50px, 50px, 50px, 50px);
  }
  .page-heading-title {
    font-size: 4.4rem;
  }
  .new-blog-container {
    width: 75%;
    margin: auto;
  }
  .release-container {
    .release-img-container {
      width: 257.3px;
      height: 257.3px;
    }
  }
  .blog-box {
    .blog-img-container {
      width: 654px;
      height: 242.08px;
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1399.9px) {
  .w-sm-max {
    width: max-content;
  }
  .item-releases-search-row-height {
    min-height: 451.3px;
    transition: all 0.3s;
  }

  .item-releases-search-msg-height {
    min-height: 451.3px;
  }

  .item-videos-search-row-height {
    min-height: 411.25px;
    transition: all 0.3s;
  }

  .item-videos-search-msg-height {
    min-height: 411.25px;
  }

  .item-news-search-row-height {
    min-height: 320.1px;
    transition: all 0.3s;
  }

  .item-news-search-msg-height {
    min-height: 320.1px;
  }
  .release-container {
    .release-img-container {
      width: 317.3px;
      height: 317.3px;
    }
  }
  .blog-box {
    .blog-img-container {
      width: 505px;
      height: 186.92px;
    }
  }
}

// -------------------------------------------

@media screen and (min-height: 0) and (max-height: 735px) {
  .virtual-nav-width {
    width: 80px !important;
    min-height: 100% !important;
    transition: all 0.2s;
  }
  .main-navbar {
    width: 80px !important;
    .icon-width {
      width: 80px !important;
    }
    .nav-icon-container {
      width: 80px !important;
    }
    &:hover {
      width: calc(22rem + 10px) !important;
    }
    .navbar-links-list {
      padding: 1.5rem 0;
    }
  }
  .mobile-nav {
    .mobile-side-nav {
      .navbar-links-list {
        padding: 1.5rem 0;
      }
    }
  }
}
