@import "../../styles/index.scss";

.code {
  font-size: 4rem;
  font-weight: 800;
  color: $fuYellowColor;
  text-align: center;
}

.oops {
  font-size: 1.5rem;
  font-weight: 300;
  color: $fuWhiteColor;
  text-align: center;
  letter-spacing: 2px;
}

.desc {
  color: $fuLightGrayColor;
  font-weight: 300;
  text-align: center;
}

@media screen and (min-width: 480px) and (max-width: 576px) {
  .code {
    font-size: 3rem;
  }

  .oops {
    font-size: 1.3rem;
  }

  .desc {
    font-size: 14px;
  }
}

@media screen and (min-width: 400px) and (max-width: 480px) {
  .code {
    font-size: 3rem;
  }

  .oops {
    font-size: 1rem;
    margin-bottom: 6px !important;
  }

  .desc {
    font-size: 14px;
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 310px) and (max-width: 400px) {
  .code {
    font-size: 3rem;
  }

  .oops {
    font-size: 0.8rem;
    letter-spacing: 2px;
    margin-bottom: 6px !important;
  }

  .desc {
    font-size: 12px;
    margin-bottom: 6px !important;
  }
}

@media screen and (min-width: 0) and (max-width: 310px) {
  .code {
    font-size: 2.5rem;
  }

  .oops {
    font-size: 0.8rem;
    margin-bottom: 6px !important;
  }

  .desc {
    font-size: 12px;
    margin-bottom: 6px !important;
  }
}
