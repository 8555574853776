@import "../../styles/index.scss";

.blog_heading_container {
  & {
    position: relative;
    background-color: $fuLightBlackColor;
    padding-bottom: 200px;
    margin-bottom: -200px;
    overflow: hidden;
    transform: translate3d(0, 0, 0);
  }
  .blog_img_section {
    & {
      position: relative;
      z-index: 10;
    }
    .blog_pic_container {
      width: 1296px;
      height: 479.7px;
      border-radius: 4px;
      overflow: hidden;
    }
  }
  .bg_blur {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow-y: hidden;
    filter: blur(50px);
    opacity: 0.2;
  }
  .gradient {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 200px;
    background-image: linear-gradient(to top, $fuDarkGrayColor, rgba($color: $fuDarkGrayColor, $alpha: 0.7), rgba($color: $fuDarkGrayColor, $alpha: 0.3), $fuTransparentColor);
  }
}

.blog_title_container {
  margin-bottom: 30px;
  .blog_title {
    font-size: 2.5rem;
    font-weight: 800;
    color: $fuWhiteColor;
    line-height: 1.2 !important;
    text-align: justify;
  }
}

.blog_description_container {
  .blog_description {
    font-size: 20px;
    color: $fuLightGrayColor;
    text-align: justify;
  }
}

@media screen and (min-width: 767.9px) and (max-width: 1199.9px) {
  .blog_title_container {
    .blog_title {
      font-size: 2rem;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 18px;
    }
  }
}

@media screen and (min-width: 575.9px) and (max-width: 767.9px) {
  .blog_title_container {
    .blog_title {
      font-size: 1.5rem;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 16px;
    }
  }
}

@media screen and (min-width: 480px) and (max-width: 576px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        margin: auto;
        width: 438px;
      }
    }
  }
  .blog_title_container {
    margin-bottom: 15px;
    .blog_title {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 14px;
    }
  }
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 438px;
        height: 162.13px;
      }
    }
  }
}

@media screen and (min-width: 400px) and (max-width: 480px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        margin: auto;
        width: 358px;
      }
    }
  }
  .blog_title_container {
    margin-bottom: 15px;
    .blog_title {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 14px;
    }
  }
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 358px;
        height: 132.5px;
      }
    }
  }
}

@media screen and (min-width: 310px) and (max-width: 400px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        margin: auto;
        width: 268px;
      }
    }
  }
  .blog_title_container {
    margin-bottom: 15px;
    .blog_title {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 14px;
    }
  }
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 438px;
        height: 162.13px;
      }
    }
  }
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 268px;
        height: 99.19px;
      }
    }
  }
}

@media screen and (min-width: 0px) and (max-width: 310px) {
  .blog_title_container {
    margin-bottom: 15px;
    .blog_title {
      font-size: 1rem;
      font-weight: 600;
    }
  }
  .blog_description_container {
    .blog_description {
      font-size: 14px;
    }
  }
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: auto;
        height: auto;
      }
    }
  }
}

@media screen and (min-width: 576px) and (max-width: 767.9px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 516px;
        height: 190.98px;
      }
    }
  }
}

@media screen and (min-width: 767.9px) and (max-width: 991.9px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 696px;
        height: 257.63px;
      }
    }
  }
}

@media screen and (min-width: 991.9px) and (max-width: 1199.9px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 936px;
        height: 346.45px;
      }
    }
  }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
  .blog_heading_container {
    .blog_img_section {
      .blog_pic_container {
        width: 1116px;
        height: 413.08px;
      }
    }
  }
}
