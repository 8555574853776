@import "../../styles/index.scss";

.release_container {
  position: relative;
  background-color: $fuLightBlackColor;
  padding-bottom: 500px;
  margin-bottom: -500px;
  overflow: hidden;
  transform: translate3d(0, 0, 0);
  .release_info_section {
    & {
      position: relative;
      z-index: 10;
    }
    .release_info {
      .release_title {
        color: $fuWhiteColor;
        font-size: 2rem;
        font-weight: 700;
        // line-height: 1;
      }
      .release_artist,
      .release_date,
      .release_catalog {
        display: block;
        font-size: 16px;
        font-weight: 300;
        color: $fuLightGrayColor;
      }
      .date,
      .catalog {
        margin-left: 5px;
        color: $fuWhiteColor;
        font-weight: 600;
      }
      .release_caption {
        color: $fuWhiteColor;
        text-align: justify;
        font-weight: 200;
        font-size: 16px;
      }
    }
    .release_img {
      width: 500px;
      height: 500px;
      margin-left: auto;
      overflow: hidden;
      border-radius: 4px;
    }
    .stream_icons_container {
      display: flex;
      .stream_icon {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        border: 2px solid $fuLightGrayColor;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 5px;
        transition: all 0.3s;
        .svg_icon {
          fill: $fuLightGrayColor;
          transition: all 0.3s;
        }
        &:hover {
          border-color: $fuYellowColor;
          .svg_icon {
            fill: $fuYellowColor;
          }
        }
      }
    }
  }

  .bg_blur {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    overflow-y: hidden;
    filter: blur(50px);
    opacity: 0.2;
  }
  .gradient {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 600px;
    background-image: linear-gradient(to top, $fuDarkGrayColor 25%, rgba($color: $fuDarkGrayColor, $alpha: 0.7) 50%, rgba($color: $fuDarkGrayColor, $alpha: 0.3) 75%, $fuTransparentColor);
  }
}
